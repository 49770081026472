var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-container" },
    [
      _c(
        "CModal",
        {
          attrs: {
            show: _vm.openPrescriptionFlg,
            centered: true,
            title: "処方"
          },
          on: {
            "update:show": function($event) {
              _vm.openPrescriptionFlg = $event
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "CButton",
                    {
                      attrs: { color: "danger" },
                      on: {
                        click: function($event) {
                          _vm.openPrescriptionFlg = false
                        }
                      }
                    },
                    [_vm._v("キャンセル")]
                  ),
                  _c(
                    "CButton",
                    {
                      attrs: { color: "success" },
                      on: {
                        click: function($event) {
                          return _vm.savePrescription()
                        }
                      }
                    },
                    [_vm._v("OK")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "div",
            [
              _c(
                "CRow",
                { staticClass: "mt-0" },
                [
                  _c(
                    "CCol",
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: { horizontal: "", label: "安静時" },
                        on: {
                          keypress: function($event) {
                            return _vm.isFloat($event)
                          }
                        },
                        model: {
                          value: _vm.prescriptionForm.rest,
                          callback: function($$v) {
                            _vm.$set(_vm.prescriptionForm, "rest", $$v)
                          },
                          expression: "prescriptionForm.rest"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CRow",
                { staticClass: "mt-0" },
                [
                  _c(
                    "CCol",
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: { horizontal: "", label: "労作時" },
                        on: {
                          keypress: function($event) {
                            return _vm.isFloat($event)
                          }
                        },
                        model: {
                          value: _vm.prescriptionForm.exercise,
                          callback: function($$v) {
                            _vm.$set(_vm.prescriptionForm, "exercise", $$v)
                          },
                          expression: "prescriptionForm.exercise"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CRow",
                { staticClass: "mt-0" },
                [
                  _c(
                    "CCol",
                    [
                      _c("CInput", {
                        staticClass: "mb-1",
                        attrs: { horizontal: "", label: "就寝時" },
                        on: {
                          keypress: function($event) {
                            return _vm.isFloat($event)
                          }
                        },
                        model: {
                          value: _vm.prescriptionForm.sleep,
                          callback: function($$v) {
                            _vm.$set(_vm.prescriptionForm, "sleep", $$v)
                          },
                          expression: "prescriptionForm.sleep"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("div", { staticClass: "my-tool-bar" }, [
        _c(
          "div",
          { staticClass: "tool-bar-item" },
          [
            _c("datepicker", {
              attrs: {
                id: "from",
                value: _vm.chartToTmp,
                language: _vm.ja,
                format: "yyyy/MM/dd",
                "bootstrap-styling": "",
                placeholder: "終了日付を選択"
              },
              model: {
                value: _vm.chartToTmp,
                callback: function($$v) {
                  _vm.chartToTmp = $$v
                },
                expression: "chartToTmp"
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "toobar-right" }, [
          _c(
            "div",
            { staticClass: "tool-bar-item" },
            [
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.dayCountTmp == 1
                  },
                  on: {
                    click: function($event) {
                      _vm.dayCountTmp = 1
                    }
                  }
                },
                [_vm._v("日 ")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.dayCountTmp == 7
                  },
                  on: {
                    click: function($event) {
                      _vm.dayCountTmp = 7
                    }
                  }
                },
                [_vm._v("週 ")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.dayCountTmp == 30
                  },
                  on: {
                    click: function($event) {
                      _vm.dayCountTmp = 30
                    }
                  }
                },
                [_vm._v(" 30日 ")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.dayCountTmp == 90
                  },
                  on: {
                    click: function($event) {
                      _vm.dayCountTmp = 90
                    }
                  }
                },
                [_vm._v(" 90日 ")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tool-bar-item" },
            [
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success ml-2 mr-1",
                    variant: "outline",
                    pressed: _vm.chartTypeTmp == "summary"
                  },
                  on: {
                    click: function($event) {
                      _vm.chartTypeTmp = "summary"
                    }
                  }
                },
                [_vm._v("サマリー")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.chartTypeTmp == "ox"
                  },
                  on: {
                    click: function($event) {
                      _vm.chartTypeTmp = "ox"
                    }
                  }
                },
                [_vm._v("酸素濃縮装置")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.chartTypeTmp == "pu"
                  },
                  on: {
                    click: function($event) {
                      _vm.chartTypeTmp = "pu"
                    }
                  }
                },
                [_vm._v("パルスオキシメータ")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "success mr-1",
                    variant: "outline",
                    pressed: _vm.chartTypeTmp == "bl"
                  },
                  on: {
                    click: function($event) {
                      _vm.chartTypeTmp = "bl"
                    }
                  }
                },
                [_vm._v("血圧計")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tool-bar-item" },
            [
              _c(
                "CButton",
                {
                  staticClass: "ml-1",
                  style: {
                    visibility:
                      _vm.chartType != "summary" ? "visible" : "hidden"
                  },
                  attrs: { size: "sm", color: "info" },
                  on: {
                    click: function($event) {
                      return _vm.csvDownload()
                    }
                  }
                },
                [_vm._v("CSV")]
              ),
              _c(
                "CButton",
                {
                  attrs: {
                    size: "sm",
                    color: "primary mr-1 ml-5",
                    pressed: false
                  },
                  on: { click: _vm.chartPrint }
                },
                [_vm._v("レポート印刷")]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "data-content" }, [
        _c("div", { staticClass: "left" }, [
          _c("div", { staticClass: "patient-info mt-3" }, [
            _c("div", [
              _c(
                "span",
                { staticStyle: { width: "105px", display: "inline-block" } },
                [_vm._v("ID")]
              ),
              _c("span", [_vm._v(_vm._s(_vm.karuteNo))])
            ])
          ]),
          _c("div", [
            _c(
              "span",
              { staticStyle: { width: "105px", display: "inline-block" } },
              [_vm._v("氏名")]
            ),
            _c("span", { staticStyle: { width: "80%" } }, [
              _vm._v(_vm._s(_vm.patientName))
            ])
          ]),
          _c("div", { staticClass: "patient-info-item" }, [
            _vm._v("処方流量(リットル/分)")
          ]),
          _c("div", { staticClass: "shohou" }, [
            _c("div", [_vm._v("安静時")]),
            _c("div", [_vm._v("労作時")]),
            _c("div", [_vm._v("就寝時")]),
            _c(
              "div",
              { staticClass: "print-invisible" },
              [
                _c(
                  "CButton",
                  {
                    staticStyle: { color: "white" },
                    attrs: { size: "sm", color: "success mr-1" },
                    on: {
                      click: function($event) {
                        return _vm.openPrescription()
                      }
                    }
                  },
                  [_vm._v("処方入力")]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "shohoou-data" }, [
            _c("div", {}, [_vm._v(_vm._s(_vm.prescription.rest))]),
            _c("div", {}, [_vm._v(_vm._s(_vm.prescription.exercise))]),
            _c("div", {}, [_vm._v(_vm._s(_vm.prescription.sleep))]),
            _c("div", {})
          ]),
          _c("table", { staticClass: "mt-5 ml-1", attrs: { width: "100%" } }, [
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [_vm._m(0)])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最大値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _vm._m(1),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_setting["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.ox_setting["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _vm._m(2),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.ox_breath["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.ox_breath["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "ox"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "6" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _c("span"),
                        _vm._v(
                          "稼働時間:" +
                            _vm._s(_vm.measureValueSummary["kadouJikan"]) +
                            _vm._s(_vm.oxHourMark) +
                            " "
                        ),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v(
                            "装着時間:" +
                              _vm._s(_vm.measureValueSummary["souchakuJikan"]) +
                              _vm._s(_vm.oxBreathHourMark)
                          )
                        ]),
                        _c("span", { staticClass: "ml-3" }, [
                          _vm._v("装着率:" + _vm._s(_vm.souchakuritsu))
                        ])
                      ]),
                      _c("div", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v(
                          "※酸素濃縮装置により検出された数値を表示していますので、"
                        )
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "padding-left": "8px"
                          }
                        },
                        [_vm._v("実際の数値とは異なる場合があります。")]
                      )
                    ]
                  )
                ])
              : _vm._e(),
            _vm._m(3),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", { staticClass: "mt-3" }, [_vm._m(4)])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最大値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _vm._m(5),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.spo2["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(_vm.measureValueSummary.spo2["standardDeviation"])
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _vm._m(6),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.pr_bpm["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.pr_bpm["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "summary" || _vm.chartType == "pu"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "7" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "表示期間のパルスオキシメータ測定回数 " +
                            _vm._s(
                              _vm.measureValueSummary.pr_bpm["measureCount"]
                            ) +
                            " 回"
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e(),
            _vm.chartType == "bl" ? _c("tr", [_vm._m(7)]) : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", { staticClass: "data-label" }, [
                  _c("td"),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最大値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("最小値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("平均値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("中央値")]),
                  _c("td", { staticClass: "text-center" }, [_vm._v("標準偏差")])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(8),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_up["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(_vm.measureValueSummary.bl_up["standardDeviation"])
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(9),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["max"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_down["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.bl_down["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _vm._m(10),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["max"] || ""))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["min"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["avg"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(_vm._s(_vm.measureValueSummary.bl_bpm["middle"]))
                  ]),
                  _c("td", { staticClass: "text-center" }, [
                    _vm._v(
                      _vm._s(
                        _vm.measureValueSummary.bl_bpm["standardDeviation"]
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm.chartType == "bl"
              ? _c("tr", [
                  _c(
                    "td",
                    {
                      staticStyle: { border: "none" },
                      attrs: { colspan: "6" }
                    },
                    [
                      _c("div", { staticClass: "text-center" }, [
                        _vm._v(
                          "表示期間の血圧計の測定回数 " +
                            _vm._s(_vm.measureValueSummary.bl_up["hour"]) +
                            " 回"
                        )
                      ])
                    ]
                  )
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            [
              _c(
                "label",
                {
                  staticClass: "mt-2 mb-0",
                  staticStyle: { "font-size": "1.1em", "font-weight": "bold" },
                  attrs: { for: "memo" }
                },
                [_vm._v("メモ")]
              ),
              _c("LimitedTextArea", {
                staticStyle: { width: "100%", height: "150px", resize: "none" },
                attrs: {
                  id: "memo",
                  maxlength: "200",
                  placeholder: "メモを入力してください。",
                  rows: "6"
                },
                on: {
                  input: function($event) {
                    _vm.memo = $event
                  }
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "right" }, [
          ["summary", "ox"].indexOf(_vm.chartType) >= 0
            ? _c(
                "div",
                [
                  _c("ox-charts7", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.dayCount < 30,
                        expression: "dayCount < 30"
                      }
                    ],
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_SETTING"],
                      "chart-width": _vm.widthHeight.OX_SETTING_WIDTH,
                      "x-title": _vm.oxChartXTitle,
                      "y-title": _vm.oxChartYTitle,
                      "day-from-to": _vm.dayFromTo
                    }
                  }),
                  _c("ox-charts90", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.dayCount >= 30,
                        expression: "dayCount >= 30"
                      }
                    ],
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_SETTING"],
                      "chart-width": _vm.widthHeight.OX_SETTING_WIDTH,
                      "x-title": _vm.oxChartXTitle,
                      "y-title": _vm.oxChartYTitle,
                      "day-from-to": _vm.dayFromTo
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.chartType == "summary"
            ? _c(
                "div",
                [
                  _vm.dayCount < 30
                    ? _c("ox-breath-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "from-series":
                            _vm.serieObject["SUMMARY_OX_BREATH_17"],
                          "day-from-to": _vm.dayFromTo,
                          "chart-title": "呼吸数",
                          ymin: 10,
                          ymax: 35,
                          "x-title": _vm.breath17XTitle,
                          "y-title": "回/分"
                        }
                      })
                    : _c("ox-breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "from-series":
                            _vm.serieObject["SUMMARY_OX_BREATH_3090"],
                          "day-from-to": _vm.dayFromTo,
                          "chart-title": "呼吸数",
                          ymin: 10,
                          ymax: 35,
                          "x-title": "日付",
                          "y-title": "回/分"
                        }
                      }),
                  _vm.dayCount < 30
                    ? _c("ox-spo2-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血中酸素飽和度/脈拍数",
                          "from-series": _vm.serieObject["SUMMARY_PU_SPO2_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getSpo2YaxisPointMin(
                            _vm.serieObject["SUMMARY_PU_SPO2_17"],
                            80
                          ),
                          ymax: 100,
                          "x-title": _vm.spo17Title,
                          "y-title": "%",
                          colors: ["#6600FF"]
                        }
                      })
                    : _c("ox-breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血中酸素飽和度/脈拍数",
                          "from-series":
                            _vm.serieObject["SUMMARY_PU_SPO2_3090"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getSpo2YaxisMin(
                            _vm.serieObject["SUMMARY_PU_SPO2_3090"],
                            80
                          ),
                          ymax: 100,
                          "x-title": "日付",
                          "y-title": "%",
                          colors: ["#6600FF"]
                        }
                      }),
                  _vm.dayCount < 30
                    ? _c("ox-pulse-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "from-series":
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getMyakuhakuYaxisPointMin(
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                            60
                          ),
                          ymax: _vm.getMyakuhakuYaxisPointMax(
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_17"],
                            180
                          ),
                          "x-title": _vm.spo17Title,
                          "y-title": "回/分",
                          colors: ["#FF0000"]
                        }
                      })
                    : _c("ox-breath-charts30", {
                        staticStyle: { "margin-top": "-40px" },
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "",
                          "from-series":
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getMyakuhakuYaxisMin(
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                            60
                          ),
                          ymax: _vm.getMyakuhakuYaxisMax(
                            _vm.serieObject["SUMMARY_PU_MYAKUHAKU_3090"],
                            180
                          ),
                          "show-xasis": false,
                          colors: ["#FF0000"],
                          "y-title": "回/分"
                        }
                      })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chartType == "ox",
                  expression: "chartType == 'ox'"
                }
              ]
            },
            [
              _vm.dayCount < 30
                ? _c("ox-breath-charts", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_BREATH_17"],
                      "chart-title": "呼吸数",
                      "day-from-to": _vm.dayFromTo,
                      ymin: 10,
                      ymax: 35,
                      "x-title": _vm.breath17XTitle,
                      "y-title": "回/分"
                    }
                  })
                : _c("ox-breath-charts30", {
                    attrs: {
                      "day-count": _vm.dayCount,
                      "from-series": _vm.serieObject["OX_BREATH_3090"],
                      "day-from-to": _vm.dayFromTo,
                      "chart-title": "呼吸数",
                      ymin: 10,
                      ymax: 35,
                      "x-title": "日付",
                      "y-title": "回/分"
                    }
                  }),
              _c("div", { staticClass: "chart-half" }, [
                _c(
                  "div",
                  [
                    _c("breath-pie", {
                      attrs: {
                        "day-count": _vm.dayCount,
                        "chart-title": "設定流量の割合",
                        "from-series-lables": _vm.serieObject["OX_PIE"]
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("breath-column", {
                      attrs: {
                        "day-count": _vm.dayCount,
                        "chart-title": "呼吸数の分布",
                        "from-series-and-category":
                          _vm.serieObject["OX_BREATH_COLUMN_DATA"],
                        "x-title": "呼吸数(回/分)",
                        "y-title": "頻度(回)"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chartType == "pu",
                  expression: "chartType == 'pu'"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "chart chart3" },
                [
                  _vm.dayCount < 30
                    ? _c("spo2-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血中酸素飽和度/脈拍数",
                          "from-series": _vm.serieObject["PU_SPO2_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getSpo2YaxisPointMin(
                            _vm.serieObject["PU_SPO2_17"],
                            80
                          ),
                          ymax: 100,
                          "x-title": _vm.spo17Title,
                          "y-title": "%",
                          colors: ["#6600FF"]
                        }
                      })
                    : _c("breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血中酸素飽和度/脈拍数",
                          "from-series": _vm.serieObject["PU_SPO2_3090"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getSpo2YaxisMin(
                            _vm.serieObject["PU_SPO2_3090"],
                            80
                          ),
                          ymax: 100,
                          "x-title": "日付",
                          "y-title": "%",
                          colors: ["#6600FF"]
                        }
                      }),
                  _vm.dayCount < 30
                    ? _c("pulse-charts", {
                        staticStyle: { "margin-top": "-20px" },
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "",
                          "from-series": _vm.serieObject["PU_MYAKUHAKU_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getMyakuhakuYaxisPointMin(
                            _vm.serieObject["PU_MYAKUHAKU_17"],
                            60
                          ),
                          ymax: _vm.getMyakuhakuYaxisPointMax(
                            _vm.serieObject["PU_MYAKUHAKU_17"],
                            180
                          ),
                          "y-title": "回/分",
                          colors: ["#FF0000"]
                        }
                      })
                    : _c("breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "",
                          colors: ["#FF0000"],
                          "from-series": _vm.serieObject["PU_MYAKUHAKU_3090"],
                          "y-title": "回/分",
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getMyakuhakuYaxisMin(
                            _vm.serieObject["PU_MYAKUHAKU_3090"],
                            60
                          ),
                          ymax: _vm.getMyakuhakuYaxisMax(
                            _vm.serieObject["PU_MYAKUHAKU_3090"],
                            180
                          )
                        }
                      })
                ],
                1
              ),
              _c("div", { staticClass: "chart-half" }, [
                _c(
                  "div",
                  [
                    _c("breath-column", {
                      attrs: {
                        "day-count": _vm.dayCount,
                        "chart-title": "血中酸素飽和度の分布",
                        "from-series-and-category":
                          _vm.serieObject["PU_SPO2_COLUMN_DATA"],
                        tickAmount: 1,
                        "x-title": "血中酸素飽和度(%)",
                        "y-title": "頻度(回)",
                        colors: ["#6600FF"]
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("breath-column", {
                      attrs: {
                        "day-count": _vm.dayCount,
                        "chart-title": "脈拍数の分布",
                        "x-title": "脈拍数(回/分)",
                        "y-title": "頻度(回)",
                        "from-series-and-category":
                          _vm.serieObject["PU_MYAKUHAKU_COLUMN_DATA"],
                        colors: ["#FF0000"]
                      }
                    })
                  ],
                  1
                )
              ])
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.chartType == "bl",
                  expression: "chartType== 'bl'"
                }
              ]
            },
            [
              _c(
                "div",
                { staticClass: "chart chart2" },
                [
                  _vm.dayCount < 30
                    ? _c("spo2-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血圧",
                          "chart-height": "400",
                          "from-series": _vm.serieObject["BL_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getBLYaxisMinMax(
                            _vm.serieObject["BL_17"],
                            60,
                            true
                          ),
                          ymax: _vm.getBLYaxisMinMax(
                            _vm.serieObject["BL_17"],
                            180,
                            false
                          ),
                          colors: ["#FF33CC", "#3399FF"],
                          "x-title": _vm.bl17Title,
                          "y-title": "mmHg",
                          "chart-height": 390
                        }
                      })
                    : _c("breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "血圧",
                          "chart-height": "390",
                          "from-series": _vm.serieObject["BL_3090"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getBL3090YaxisMinMax(
                            _vm.serieObject["BL_3090"],
                            60,
                            true
                          ),
                          ymax: _vm.getBL3090YaxisMinMax(
                            _vm.serieObject["BL_3090"],
                            180,
                            false
                          ),
                          colors: ["#FF33CC", "#3399FF"],
                          "x-title": "日付",
                          "y-title": "mmHg",
                          "chart-height": 390
                        }
                      })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "chart chart2" },
                [
                  _vm.dayCount < 30
                    ? _c("spo2-charts", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "脈拍数",
                          "chart-height": "390",
                          "from-series": _vm.serieObject["BL_HEART_17"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getBLYaxisMinMax(
                            _vm.serieObject["BL_HEART_17"],
                            60,
                            true
                          ),
                          ymax: _vm.getBLYaxisMinMax(
                            _vm.serieObject["BL_HEART_17"],
                            180,
                            false
                          ),
                          colors: ["#FF0000"],
                          "x-title": _vm.bl17Title,
                          "y-title": "回/分"
                        }
                      })
                    : _c("breath-charts30", {
                        attrs: {
                          "day-count": _vm.dayCount,
                          "chart-title": "脈拍数",
                          "chart-height": "390",
                          "from-series": _vm.serieObject["BL_HEART_3090"],
                          "day-from-to": _vm.dayFromTo,
                          ymin: _vm.getBL3090YaxisMinMax(
                            _vm.serieObject["BL_HEART_3090"],
                            60,
                            true
                          ),
                          ymax: _vm.getBL3090YaxisMinMax(
                            _vm.serieObject["BL_HEART_3090"],
                            180,
                            false
                          ),
                          colors: ["#FF0000"],
                          "x-title": "日付",
                          "y-title": "回/分"
                        }
                      })
                ],
                1
              )
            ]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      { staticStyle: { border: "none" }, attrs: { colspan: "6" } },
      [
        _c("label", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("酸素濃縮装置")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("流量（リットル/分）")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("分時呼吸数(回/分)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", { staticClass: "border-none", attrs: { colspan: "6" } }, [
        _c("label", { staticStyle: { color: "transparent" } }, [_vm._v("-")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "border-none", attrs: { colspan: "6" } }, [
      _c("label", { staticStyle: { "font-weight": "bold" } }, [
        _vm._v("パルスオキシメータ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("血中酸素飽和度(％)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("脈拍数(回/分)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "td",
      {
        staticClass: "device",
        staticStyle: { border: "none" },
        attrs: { colspan: "6" }
      },
      [
        _c("label", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("血圧計")
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("収縮期血圧(mmHg)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("拡張期血圧(mmHg)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", [
      _c("div", { staticClass: "ml-2" }, [_vm._v("脈拍数(回/分)")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }